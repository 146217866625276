window.Cookies = require('js-cookie/src/js.cookie');

class CookieBar {
  constructor() {
    this.cookieBar = $("#cookie-bar");
    this.cookieName = 'allow_cookies';
  }

  init() {
    if (this.areCookiesAllowed()) {
      this.appendScripts();
    }

    this.addHandlers();
  }

  areCookiesAllowed() {
    return Cookies.get(this.cookieName) == 'true';
  }

  addHandlers() {
    if (this.cookieBar.length == 0) {
      return;
    }

    this.cookieBar.find('.accept-button').click(() => this.allowCookies());
    this.cookieBar.find('.decline-button').click(() => this.declineCookies());
  }

  appendScripts() {
    const gaId = $('body').data('ga-id');
    const ga = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){" +
      "(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o)," + 
      "m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)" + 
      "})(window,document,'script','//www.google-analytics.com/analytics.js','ga');" + 
      `ga('create', '${gaId}', 'auto');` + 
      "ga('send', 'pageview');"
    $('head').append($('<script>' + ga + '</script>'));
  }

  allowCookies() {
    Cookies.set(this.cookieName, 'true', {expires: 365});
    this.appendScripts();
    this.cookieBar.remove();
  }

  declineCookies() {
    Cookies.set(this.cookieName, 'false', {expires: 365});
    this.cookieBar.remove();
  }
}

window.onload = function() {
  new CookieBar().init();
}