import Icon from 'src/js/icons';

export default function buttonToggle() {
  const btn = document.querySelectorAll('.button-toggle');
  const iconPlus = Icon('plus');
  const iconMinus = Icon('minus');
  for(let i = 0; i < btn.length; i++ ) {
    btn[i].insertAdjacentHTML('afterbegin', iconPlus);
    btn[i].addEventListener('click', function(event) {
      let icon;
      let isExpanded = btn[i].getAttribute("aria-expanded");

      btn[i].removeChild(btn[i].childNodes[0]);

      if(isExpanded === "true") {
        isExpanded = false;
        icon = iconPlus;
      } else {
        isExpanded = true;
        icon = iconMinus;
      }

      btn[i].insertAdjacentHTML('afterbegin', icon);
      btn[i].setAttribute("aria-expanded", isExpanded);

      // Add a class to the parent legend to toggle the accordion
      const legend = event.target.parentNode;
      legend.classList.toggle('row-expanded');
    });
  }
}