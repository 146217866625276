import showPreviewImage from 'src/js/preview-image';

// Create events for the image uploading so we can create a progress-bar
const fileUploader = {
  init: function(){
    // refile upload progress
    this.uploadsSuccessful = 0,
    this.uploadsFailed = 0,
    this.uploadsCompleted = 0,
    this.uploadsTotal = 0,
    this.progressBarParent = null;
    
    if($(this.wrapper).hasClass('group-image-upload')) {
      this.form = $(this.wrapper).parents('form')[0];
    } else {
      this.form = $(this.wrapper)[0];
    }
    // if($(element).hasClass('single-image-upload')) {
    //   form = $(element)[0];
    // } else {
    //   form = $(element).parents('form')[0];
    // }
    
    this.form.addEventListener('upload:start', function(event){
      this.doStart(event);
    }.bind(this));
    this.form.addEventListener('upload:progress', function(event){
      this.doProgress(event);
    }.bind(this));
    this.form.addEventListener('upload:success', function(event){
      this.doSuccess(event);
    }.bind(this));
    this.form.addEventListener('upload:failure', function(event){
      this.doFailure(event);
    }.bind(this));
    this.form.addEventListener('upload:complete', function(event){
      this.doComplete(event);
    }.bind(this));
  },
  doStart: function(event, element){
    $('form').find("input[type=submit]").prop("disabled", true);

    // store parent and create a total count indicator
    if (this.progressBarParent == null) {
      this.progressBarParent = $(this.wrapper).find('.progress-bar-container');
      this.progressBarParent.append('<div id="uploadsCount">0 / 0 completed.</div>');
    }

    $('.add_fields').hide();
    // remove original bar
    $('.progress', this.progressBarParent).remove();
    this.progressHtml = '<div class="progress" aria-valuemax="100" aria-valuemin="0" aria-valuenow="0" class="progress-bar" role="progressbar"><span class="progress-bar-label">'+event.detail.file.name+' : 0%</span>';
    this.progressHtml += '<div class="progress-meter" style="height:24px; width: 0%;">';
    this.progressHtml += '</div></div>';
    this.progressBarParent.append(this.progressHtml);
    // add to total count
    this.uploadsTotal = 1;
  },
  doProgress: function(event){
    // Get the progress bar to modify
    this.progressBar = $('.progress-meter', this.progressBarParent);
    // show entire bar
    this.progressBar.parent().removeClass('hidden');
    // Process upload details to get the percentage complete
    this.uploadDetail = event.detail;
    this.percentageComplete = Math.round((this.uploadDetail.progress.loaded / this.uploadDetail.progress.total) * 100);
    if (Number.isNaN(this.percentageComplete)) {
      this.percentageComplete = 0;
    }
    // Reflect the percentage on the progress bar
    this.progressBar.css({
      "height": 25,
      "width": this.percentageComplete + "%"
    });
    this.progressText = event.detail.file.name+' : '+ this.percentageComplete+'%';
    this.progressBar.siblings('.progress-bar-label').text(this.progressText);
  },
  doSuccess: function(event){
    this.progressBar = $('.progress-meter', this.progressBarParent);
    this.progressBar.addClass("progress-bar-success");
    // add to successful count
    this.uploadsSuccessful++;
    this.progressBar.parent().addClass("progress-finished"); // hide entire bar
  },
  doFailure: function(event){
    this.progressBar = $('.progress-meter', this.progressBarParent);
    this.progressBar.addClass("progress-bar-error");
    // add to failed count
    this.uploadsFailed = 1;
  },
  doComplete: function(event){
    this.progressBar = $('.progress-bar', this.progressBarParent);
    this.finishedMessage = "";
    // add to completed count
    this.uploadsCompleted = 1;
    if (this.uploadsFailed > 0) {
      $("#uploadsCount", this.progressBarParent).html(this.uploadsCompleted+' / '+this.uploadsTotal+' completed ('+this.uploadsFailed+' failed)');
    } else {
      $("#uploadsCount", this.progressBarParent).html(this.uploadsCompleted+' / '+this.uploadsTotal+' completed <b> - Your image has uploaded successfully.</b>');
    }
    if(this.uploadsCompleted >= this.uploadsTotal) {
      showPreviewImage($(this.wrapper).find('input[type=file]'), 300, 150);
      $('.add_fields').text('+ Add another image').fadeIn();
      $('form').find("input[type=submit]").prop( "disabled", false );
    }
  }
}

export default fileUploader;