import * as Highcharts from 'highcharts/highstock';
import 'magnific-popup';

export default function chartsReady() {

  // Set some global options
  Highcharts.setOptions({
    chart: {
      backgroundColor: 'transparent',
      reflow: true,
      spacingTop: 0,
      spacingRight: 0,
      spacingLeft: 0,
      type: 'line'
    },
    colors: ['#000000'],
    title: false,
    plotOptions: {
      series: {
        marker: {
          enabled: false
        }
      }
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      title: false
    },
    xAxis: {
      lineWidth: 0,
      minorGridLineWidth: 0,
      labels: {
        enabled: false
      },
      minorTickLength: 0,
      tickLength: 0,
      title: false,
      startOnTick: false,
      endOnTick:false
    },
    tooltip: {
      shared: true,
      useHTML: true,
      headerFormat: '<span style="font-size: 14px"><b>{point.key}</b></span><table>',
      pointFormat: '<tr><td>{series.name}: </td><td><b>{point.y}</b></td></tr>',
      footerFormat: '</table>'
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    }
  });

  function getCustomChartOptions(ref) {
    const ref_name = ref.replace('_no_unit', '');
    switch(ref_name) {
      case 'ecoli':
        return {
          yAxis:{
            plotLines: [{
                color: 'black',
                width: 2,
                value: 200,
                label: {
                  text: 'Safe Level : 200',
                  align: 'left',
                  y: 12
                }
            }]   
          },
          series: [{
            name: 'Ecoli',
            data: chart_data[ref],
            color: '#FF0000',
            negativeColor: '#0088FF',
            threshold: 200
          }]
        };
      case 'enterococci':
        return {
          yAxis:{
            plotLines: [{
                color: 'black',
                width: 2,
                value: 200,
                label: {
                  text: 'Safe Level : 200',
                  align: 'left',
                  y: 12
                }
            }]   
          },
          series: [{
            name: 'Enterococci',
            data: chart_data[ref],
            color: '#FF0000',
            negativeColor: '#0088FF',
            threshold: 200
            }]
        };
      case 'total_coliform':
        return {
          yAxis:{
            plotLines: [{
                color: 'black',
                width: 2,
                value: 200,
                label: {
                  text: 'Safe Level : 200',
                  align: 'left',
                  y: 12
                }
            }]   
          },
          series: [{
            name: 'Total Coliform',
            data: chart_data[ref],
            color: '#FF0000',
            negativeColor: '#0088FF',
            threshold: 200
            }]
        };
      case 'trash_categories':
        return {
          chart: {
            renderTo: 'chart_trash_categories',
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
          },
          colors: ['#246EC0', '#46B64B', '#D59229', '#D42626', '#E1DA1B'],
          tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
          },
          legend: {
            enabled: true,
            layout: 'vertical',
            align: 'right',
            width: 160,
            verticalAlign: 'middle',
            useHTML: true,
            labelFormatter: function() {
               // return '<div class="pie-chart-label"><b>' + this.name + '</b> - ' + this.y + '%</div>';
               return '<div class="pie-chart-label"><b>' + this.name + '</b></div>';
            }
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: false,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
              },
              showInLegend: true
            }
          },
          series: [{
            name: "",
            colorByPoint: true,
            data: chart_data[ref],
          }],
          responsive: {
            rules: [{
                condition: {
                  maxWidth: 600
                },
                chartOptions: {
                  legend: {
                      align: 'center',
                      verticalAlign: 'bottom',
                      layout: 'horizontal'
                  }
                }
            }]
          }
        }
      default:
        return {};
    }
  }

  // Create each chart on the page
  $(".chart-overview").each((_i, chart) => {
    const chart_ref = $(chart).data('ref');
    new Highcharts.Chart({
      chart: {
        renderTo: chart.id,
      },
      series: [{
        name: $(chart).data('friendly-name'),
        data: chart_data[chart_ref]
      }],
      ...getCustomChartOptions(chart_ref)
    }).reflow();
  });

  // Loading chart breakdowns in modal window
  $('.observation-chart-breakdown').on('click', function() {
    var chart = $(this).data('chart'),
        title = $(this).data('title'),
        data = chart_data[chart],
        breakdownChart;
    data.forEach((item, index) => {
      var date = new Date(data[index][0]).getTime();
      data[index][0] = date;
    });
    $.magnificPopup.open({
      items: {
        src: '#chart_breakdown_'+chart
      },
      type: 'inline',
      removalDelay: 300,
      mainClass: 'mfp-fade mfp-full-screen',
      closeBtnInside: true,
      callbacks: {
        beforeOpen: function() {
          var container = 'chart_breakdown_'+chart;
          breakdownChart = Highcharts.stockChart(container, {
              title: {
                text: title,
                useHTML: true
              },
              series: [{
                name: title,
                data: data,
                tooltip: {
                  valueDecimals: 2
                }
              }]
          });
        },
        open: function() {
          breakdownChart.reflow();
        }
      }
    });
  });
};