import ImageTools from 'src/js/image-tools';

// Show a preview once the image is uploaded
export default function showPreviewImage(element, width, height) {
  var fileList = $(element).prop("files");
  var anyWindow = window.URL || window.webkitURL;
  for(var i = 0; i < fileList.length; i++){
    ImageTools.resize(fileList[i], {
      width: width, // maximum width
      height: height // maximum height
    }, function(blob, didItResize) {
      var objectUrl = anyWindow.createObjectURL(blob),
          previewArea = $(element).parents('.image-upload-block').find('.image-preview-area');
      $('#image-preview').remove();
      previewArea.append('<img src="' + objectUrl + '" class="preview-image"/>');
      window.URL.revokeObjectURL(blob);
    });
  }
}