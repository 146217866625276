/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Turbolinks from 'turbolinks';
import 'jquery-ujs';
import 'sortablejs';
import 'timeago';
import 'bootstrap/js/dist/collapse';
import moment from 'moment';
import 'eonasdan-bootstrap-datetimepicker';
import 'jquery.cookie';
import 'spin.js';
import 'magnific-popup';
import 'owl.carousel';
import 'cocoon-rails';
import Sortable from 'sortablejs';
import '../src/js/alert-changes';
import '../src/js/transition';
import '../src/js/refile';
import '../src/js/accordion.js';
import '../src/js/cookies';
import buttonToggle from '../src/js/button-toggle';
import showPreviewImage from '../src/js/preview-image';
import fileUploader from '../src/js/file-uploader';
import chartsReady from '../src/js/charts';
import '../src/js/jquery.tokeninput.min.js';
import SmoothScroll from 'smooth-scroll';
import 'readmore-js';
//import '../src/js/google_analytics';

// Start Turbolinks
Turbolinks.start();

// Make radio buttons deselctable

function observationRadios(name){
  // Listen for mouseup and click events on the observation data radio inputs
  // so we can perform different actions on the event
  $('input[type="radio"][name^=' + name + ']').on('click mouseup', function(event) {
    var parentField = $(this).parents('.form-group');
    // If mouseup, we run a check to 'uncheck' the radio if the user doesn't want it
    // and also disable the text input and remove any value they input
    if(event.type == 'mouseup') {
      if ($(this).prop('checked')) {
        $(this).one('click', function () {
          $(this).prop('checked', false);
          if($(this).hasClass('input-toggle')) {
            parentField.find('.other-input').attr('disabled', true).val("");
          }
        });
      }
    }
    // If click, and has a input we want to toggle disabled for input and remove the value
    if(event.type == 'click') {
      if($(this).hasClass('input-toggle')) {
        parentField.find('.other-input').attr('disabled', false);
      }
      if($(this).hasClass('radio-toggle')) {
        parentField.find('.other-input').attr('disabled', true).val("");
      }
    }
  });
  // We want to make the disabled input active on click, but
  // because disabled elements don't capture click events lets bubble it to the parent
  $("input.other-input:disabled").closest("span").on('click',function() {
    var parentField = $(this).parents('.form-group');
    // Make it enabled again
    $(this).find("input:disabled").prop("disabled", false).focus();
    // Find the closest radios and enable/disable them
    parentField.find('.input-toggle').prop('checked', true);
    parentField.find('.radio-toggle').prop('checked', false);
  });
};

// Popup links for social sharing
window.socialPopup = function() {
  if (!navigator.userAgent.match(/(Mobile|webOS|Android|iPhone|iPad|iPod|Windows Phone|Opera Mobi|Kindle|BackBerry|PlayBook)/)) {
    $('.popup-link').on('click', function(e){
      e.preventDefault();
      var url = $(this).attr('href');
      window.open(url,'Sharing','toolbar=no,location=no,menubar=no,scrollbars=no,resizable=no,width=555,height=555');
    });
  }
}

window.openBadgePopup = function() {
  $.magnificPopup.open({
    items: {
      src: '#badge-notice' 
    },
    type: 'inline',
    removalDelay: 300,
    mainClass: 'mfp-fade modal-badge',
    callbacks: {
      open: function() {
        socialPopup();
      }
    }
  });
}

function hideNotices() {
  if($('body').is('#waterranger-app')) {
    if($('.notice').length) {
      setTimeout(function(){ 
        $('.notice').fadeOut("fast",function() {
          $(this).remove();
        });
      }, 8000);
    }
  }
}

var siteReady;
siteReady = function() {

  // Init out charts
  chartsReady();

  // Setup smoothScroll
  const scroll = new SmoothScroll();

  // Fire up foundation
  // $(document).foundation();

  // Hide the location input as display it on the map
  //$('.location-inputs').hide();

  var body = $('body')

  // Add some actions to observation radios for deselecting and make text inputs disabled
  observationRadios('observation');

  // Add click event listeners to any elements that have a data-toggle class
  setupDataToggles();

  // Hide alert notices after a short time
  hideNotices();

  // Read more blocks
  $('.read-more').readmore();

  // Toggle buttons
  buttonToggle();

  // Carousels
  if($('.group-carousel .card').length > 1) {
    $('.group-carousel').owlCarousel({
      margin: 32,
      items: 1,
      loop: true,
      autoplaySpeed: 3000
    });
  }

  // Hide alert notices after a short time
  if($('body').is('#waterranger-app')) {
    if($('.notice').length) {
      setTimeout(function(){ 
        $('.notice').fadeOut("fast",function() {
          $(this).remove();
        });
      }, 3000);
    }
  }

  // Close notices
  $('.close-notice').on('click', function(e){
    e.preventDefault();
    $('[class*="notice"]').fadeOut("fast",function() {
      $(this).remove();
    });
  });

  // Switch tabs to dropdown on smaller screens
  // $('#tab-bar a').on('click', function(e) {
  //   console.log(e);
  // });

  // County, State, Province select
  $('select#country-select').on('change', function() {
    var country_code = $(this).val(),
        url = $(body).hasClass('users-edit_profile') ? "/user_state_province_options" : "/group_state_province_options"
    $.ajax({
      url: url,
      type: 'GET',
      data: { 'parent_country': country_code },
      success: function(result) {
        // console.log(result);
      }
    });
  });

  // Media query for anchor links in tabs
  if(location.hash) {
    if(window.innerWidth > 760) {
      window.scrollTo(0, 0);
      setTimeout(function(){
        window.scrollTo(0, 0);
      }, 1);
    } else {
      const locHash = location.hash;
      let scrollLeft = 0;
      window.scrollTo(0, 0);
      scroll.animateScroll(document.querySelector(locHash), 0, {
        speed: 500,
        header: '.site-header',
        offset: 10
      });
      // Restore the menu position
      document.addEventListener("turbolinks:render", function(e) {
        $('.tabs').scrollLeft(scrollLeft);
      });
      document.addEventListener("turbolinks:before-visit", function(e) {
        scrollLeft = $('.tabs').scrollLeft();
      });
    }
  }

  // Hack for group contact links
  $('.contact a[href^="mailto"]').text('Email us');
  $('.contact a[href^="http"]').text('Visit our website');

  // Resp Nav 
  $('.responsive-menu').on('click', function(e){
    e.preventDefault();
    if(!body.hasClass('responsive-menu-expanded')) {
      body.addClass('responsive-menu-expanded');
    } else {
      body.removeClass('responsive-menu-expanded');
    }
  });

  // Observation data fields
  function setupDataToggles() {
    var dataToggle = $('.data-toggle');
    $('.data-toggle').addClass('closed');
    $('.observation-form-data').addClass('hide');
    dataToggle.on('click', function(e){
      e.preventDefault();
      var scrollPos = $(this).offset().top -60,
          dataField = $(this).closest('.field-toggle').find('.observation-form-data');
      if(!dataField.hasClass('hide')) {
        $(this).removeClass('open');
        dataField.addClass('hide');
      } else {
        $(this).addClass('open');
        dataField.removeClass('hide');
        $('html, body').animate({
          scrollTop: scrollPos
        }, 500);
      }
    });
  }

  $('.observation_digest_selector').on('change', function(){
    var $this = $(this);
    if($('.tick').length) {
      $('.tick').remove();  
    }
    $this.after('<img src="/assets/loader.gif" alt="loader" class="inline-loader"/>');
    $.ajax({
      url: "digest_update",
      type: 'POST',
      data: {'membership_id': $this.parent().data('membership-id'), 'digest': $this.val()},
      dataType : 'html',
      success: function(result) {
        setTimeout(function(){
          $this.parent().find('.inline-loader').remove();
          $this.after('<span class="tick"></span>');
        }, 500);
      }
    });
  });
  
  $('#observation_group_tokens').on('change', function(){
    var group_id = $('#observation_group_tokens').val(),
        user_id = $('#observation_user_id').val(),
        form_data = $('.observation-data-fields :input').serializeArray();
        console.log(form_data);
    $.ajax({
        url: "get_form",
        type: 'GET',
        data: {'group': group_id, 'user': user_id},
        success: function(result) {
          setupDataToggles();
          observationRadios('observation');

          // Rebind help modals
          $('.ajax-modal').magnificPopup({
            type: 'ajax',
            removalDelay: 300,
            mainClass: 'mfp-fade'
          });
          
          //put the original_form back into the new form loaded
          $.each(form_data, function(i, fd) {
            if(~fd.name.indexOf('water_quality_tests') && fd.value != "") {
              //loop through all the children and only fill them in if they are blank
              //inputs have multiple inputs and have the same id, that's why we need to get parent and then children
              var testSamples = $('input[name="'+fd.name+'"]').parent().children().toArray();
              testSamples.some(function(ti){
                if (ti.value == ""){  
                  ti.value = fd.value
                  return true;
                }
              });
            
              // it's a radio button option if it's length is 3, need to handle it differently
              if( $('input[name="'+fd.name+'"]').length == 3){
                //don't set the first radio button to checked, if 'other' is already checked
                if (!$('input[name="'+fd.name+'"]')[1].checked){
                  $('input[name="'+fd.name+'"]')[0].checked = true;
                }
                
                //If it's an exact value, then need to clear the disable and set the value.
                if ($('input[name="'+fd.name+'"]')[1].checked && fd.value != "other"){
                  $('input[id="'+fd.name.replace(/\[/g,'_').replace(/\]/g,'')+'"]').attr('disabled', false).val(fd.value);
                }
            
                //if the value is other, then we need to select the 'exact' radio button and clear the 'option' radio button
                if(fd.value == "other"){
                  $('input[name="'+fd.name+'"]')[0].checked = false;
                  $('input[name="'+fd.name+'"]')[1].checked = true;
                }
              }
            }
            
            if(~fd.name.indexOf('wildlife') || ~fd.name.indexOf('invasive_species')) {
              $('input[value="'+fd.value+'"]').prop( "checked", true );
            }

          }); 
        }
    });
  });

  // Issue data fields
  var issueChoice = $('.issue-choice'),
      issueInformation = $('.issue-information'),
      listItems = issueChoice.find('.card'),
      issueSubmit = $('.issue-submit');
  if($('body').hasClass('issues-new')) {
    issueInformation.addClass('hidden');
    issueSubmit.prop('disabled', true);
    $('.add-more').show();
  }
  
  if($('body').hasClass('issues-edit')) {
    $('.iss-add-card').each(function(){
      const radio = $(this).find('input[type="radio"]');
      if(radio.is(':checked')) {
        $(this).addClass('active');
      }
    });
    // initEpaData();
  }
  
  $(document).on('change', '.iss-add-card', function (e) {
    const $this = $(this);

    $('.iss-add-card').removeClass('active');
    $this.addClass('active');
    if($('body').hasClass('issues-new')) {
      $('.add-more').fadeOut();
      issueSubmit.prop('disabled', false);
    }
    
    var user_id = $('#issue_user_id').val(),
        category =  $this.find('input[type="radio"]').val(),
        group_id = $('#issue_group_tokens').val();

    $.ajax({
      url: "get_form",
      type: 'GET',
      data: {'group': group_id, 'user': user_id, 'category': category},
      success: function(result) {
        issueInformation.removeClass('hidden');
        // initEpaData();
        $('html, body').animate({
          scrollTop: issueInformation.offset().top - 20
        }, 500);
        // Rebind modal links
        $('.ajax-modal').magnificPopup({
          type: 'ajax',
          removalDelay: 300,
          mainClass: 'mfp-fade'
        });
        // Rebind toggle buttons
        buttonToggle();
      }
    });
  });

  if($('body').hasClass('observations-edit')) {
    $('.obs-add-card').each(function(){
      const checkbox = $(this).find('input[type="checkbox"]');
      if(checkbox.is(':checked')) {
        $(this).addClass('active');
      }
    });
    // initEpaData();
  }

  // Obs data fields
  $(document).on('change','.obs-add-card', function(e) {
    $(this).toggleClass('active');
  });

  // function initEpaData() {
  //   const epaData = $('.epa-data-fields');
  //   const epaDataToggle = $('.toggle-epa-data');
  //   // Toggle EPA data view
  //   if(epaData.length) {
  //     // If editing check if any fields have been added
  //     if($('body').hasClass('issues-edit')) {
  //       epaData.find("input[type=text], textarea").each(function(){
  //         if(!$(this).val() === '') {
  //           epaData.addClass('hidden');
  //         } else {
  //           epaDataToggle.hide();
  //         }
  //         return false;
  //       })
  //     } else {
  //       epaData.addClass('hidden');
  //     }
  //     epaDataToggle.on('click', function() {
  //       epaData.removeClass('hidden');
  //       const $this = $(this);
  //       $this.text('Cancel cleanup?');
  //       if($this.hasClass('cancel-cleanup')) {
  //         const cf = confirm('Cancelling this cleanup will remove values from any fields you have added. Are you sure you want to cancel?');
  //         if(cf) {
  //           epaData.find("input[type=text], textarea").val("");
  //           epaData.addClass('hidden');
  //           $this.removeClass('cancel-cleanup');
  //           $this.text('Would you like to conduct a cleanup?');
  //         }
  //       } else {
  //         $this.addClass('cancel-cleanup');
  //       }
  //     });
  //   }
  // }
  
  // $('#issue_group_tokens').on('change', function(){
  //   var $this = $(this),
  //       group_id = $this.val(),
  //       user_id = $('#issue_user_id').val(),
  //       category = $("input[name='issue[category]']:checked").val();
  //   $.ajax({
  //       url: "get_form",
  //       type: 'GET',
  //       data: {'group': group_id, 'user': user_id, 'category': category},
  //       success: function(result) {
  //         console.log('Loaded issue form');
  //       }
  //   });
  // });

  // Modal windows
  $('.inline-modal').magnificPopup({
    type: 'inline',
    removalDelay: 300,
    mainClass: 'mfp-fade'
  });

  $('.iframe-modal').magnificPopup({
    type: 'iframe',
    removalDelay: 300,
    mainClass: 'mfp-fade'
  });

  $('.ajax-modal').magnificPopup({
    type: 'ajax',
    removalDelay: 300,
    mainClass: 'mfp-fade'
  });

  $('.image-modal').magnificPopup({
    type: 'image',
    removalDelay: 300,
    mainClass: 'mfp-fade'
  });

  $('.ajax-image-modal').magnificPopup({
    type: 'ajax',
    tLoading: 'Loading image...',
    mainClass: 'mfp-ajax-image',
    closeBtnInside: false
  });

  $('.thumb-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-ajax-image',
    closeBtnInside: false,
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0,1] // Will preload 0 - before current, and 1 after the current image
    }
  });

  if($('body').hasClass('map-index')) {
    if(typeof $.cookie('waterrangers_welcome') === 'undefined') {
      $.magnificPopup.open({
        items: {
          src: '#welcome-message' 
        },
        type: 'inline',
        removalDelay: 300,
        mainClass: 'mfp-fade'
      });
      $.cookie('waterrangers_welcome', 'true');
    }
  }

  if($('.badge-notice').length) {
    setTimeout(function(){
      loadBadgeNotice();
    }, 1000);
  }

  // Popup links
  socialPopup();

  var loadBadgeNotice = function() {
    $.magnificPopup.open({
      items: {
        src: '#badge-notice' 
      },
      type: 'inline',
      removalDelay: 300,
      mainClass: 'mfp-fade modal-badge'
    });
  }

  function randomstring(L){
    var s= '';
    var randomchar=function(){
      var n= Math.floor(Math.random()*62);
      if(n<10) return n; //1-10
      if(n<36) return String.fromCharCode(n+55); //A-Z
      return String.fromCharCode(n+61); //a-z
    }
    while(s.length< L) s+= randomchar();
    return s;
  }

  let blockCount = 0;
  $('.image-upload-fields')
  .on('cocoon:before-insert', function(e,item_to_be_added) {
    item_to_be_added.fadeIn('slow');
    blockCount++;
    item_to_be_added.prop('id', "nested-field-"+blockCount);
  })
  .on('cocoon:after-insert', function(e, added_item) {
    const inputs = added_item.find('input');
    const ref = randomstring(32);
    inputs.each(function(){
      $(this).attr('data-reference', ref);
    });
    const imagesUploader = Object.create(fileUploader);
    imagesUploader.wrapper = added_item;
    imagesUploader.init(added_item);
  })
  .on('cocoon:before-remove', function(e, item) {
    // allow some time for the animation to complete
    item.find($('.progress-bar')).remove();
    $(this).data('remove-timeout', 1000);
    item.fadeOut('slow');
    $('.add_fields').text('+ Add another image').fadeIn();
    $('form').find("input[type=submit]").prop( "disabled", false );
  });
  
  if($('.single-image-upload').length) {
    var singleImageUploader = $('.single-image-upload').find('input[type=file]');
    singleImageUploader.on('change', function(event){
      showPreviewImage(singleImageUploader, 300, 300);
    });
    const singleImgUploader = Object.create(fileUploader);
    singleImgUploader.wrapper = ".single-image-upload";
    singleImgUploader.init();
  }
  
  if($('.groupImg-image-upload').length) {
    var groupImgImageUpload = $('.groupImg-image-upload').find('input[type=file]');
    groupImgImageUpload.on('change', function(event){
      showPreviewImage(groupImgImageUpload, 300, 300);
    });
    const groupImgUploader = Object.create(fileUploader);
    groupImgUploader.wrapper = ".groupImg-image-upload";
    groupImgUploader.init();
  }
  
  if($('.groupAreaImg-image-upload').length) {
    var groupAreaImgImageUpload = $('.groupAreaImg-image-upload').find('input[type=file]');
    groupAreaImgImageUpload.on('change', function(event){
      showPreviewImage(groupAreaImgImageUpload, 300, 300);
    });
    const groupAreaImgUploader = Object.create(fileUploader);
    groupAreaImgUploader.wrapper = ".groupAreaImg-image-upload";
    groupAreaImgUploader.init();
  }
  
  // Disable mousewheel on a input number field when in focus, to prevent scrolling and editting number
  $('form').on('focus', 'input[type=number]', function (e) {
    $(this).on('mousewheel.disableScroll', function (e) {
      e.preventDefault()
    })
  });
  $('form').on('blur', 'input[type=number]', function (e) {
    $(this).off('mousewheel.disableScroll')
  });

  // Submit search forms through turbo links
  $(document).on("submit", ".search-form form", function(e) {
    var searchInput = $(this).find('input[type="search"]');
    searchInput.val($.trim(searchInput.val()));
    Turbolinks.visit(this.action+(this.action.indexOf('?') == -1 ? '?' : '&')+$(this).serialize());
    return false;
  });

  // Timeago conversion on the client
  $("time.timeago").timeago();
  
  // Date picker
  if (!navigator.userAgent.match(/(Mobile|webOS|Android|iPhone|iPad|iPod|Windows Phone|Opera Mobi|Kindle|BackBerry|PlayBook)/)) {
    // var today = startOfToday(),
    //     todayAdd = addDays(today, 1),
    //     maxDateResult = subSeconds(todayAdd, 1);
    $('#datetimepicker1').datetimepicker({
      format: 'YYYY-MM-DD HH:mm',
      keepOpen: false, 
      allowInputToggle: true, 
      sideBySide: true,
      maxDate: moment().startOf('day').add(1, 'days').subtract(1, 'seconds'),
      // maxDate: maxDateResult,
      useCurrent: false,
      debug: false,
      showTodayButton: true,
      toolbarPlacement: 'bottom'
    });
  };

  // Add a blank image for 404
  // $('img').on('error', function(){
  //   $(this).attr('src', '/assets/default-300x300.jpg');
  // });

  // Sortable lists
  if($('body').hasClass('edit_custom_form')) {

    const availableTestsList = document.getElementById('water-tests-available'),
        customTestsList = document.getElementById('water-tests-custom'),
        removedTestsList = document.getElementById('water-tests-removed');

    const defaultTests = new Sortable(availableTestsList, {
      group: {
        name: "water_tests",
        pull: true
      },
      sort: false,
      animation: 100,
      onAdd: function (evt) {
        const inputItem = evt.item.querySelector('input');
        const fromList = evt.from.id.split('-').slice(-1)[0]; //Split the returned ID by - and get the last word which is the actual name of the string
        const regexp = new RegExp(fromList, "gi");
        const tempName = inputItem.name.slice(inputItem.name.search('_'));
        inputItem.name = "custom"+tempName.replace(regexp, "available");
      }
    });

    const customTests = new Sortable(customTestsList, {
      group: {
        name: "water_tests",
        put: true
      },
      sort: true,
      animation: 100,
      onAdd: function (evt) {
        const inputItem = evt.item.querySelector('input');
        const fromList = evt.from.id.split('-').slice(-1)[0]; //Split the returned ID by - and get the last word which is the actual name of the string
        const regexp = new RegExp(fromList, "gi");
        const tempName = inputItem.name.slice(inputItem.name.search('_'));
        inputItem.name = "custom"+tempName.replace(regexp, "custom");
      }
    });
    
    const removedTests = new Sortable(removedTestsList, {
      group: {
        name: "water_tests",
        put: true
      },
      sort: true,
      animation: 100,
      onAdd: function (evt) {
        const inputItem = evt.item.querySelector('input');
        const fromList = evt.from.id.split('-').slice(-1)[0]; //Split the returned ID by - and get the last word which is the actual name of the string
        const regexp = new RegExp(fromList, "gi");
        const tempName = inputItem.name.slice(inputItem.name.search('_'));
        inputItem.name = "custom"+tempName.replace(regexp, "removed");
      }
    });

  }

};

document.addEventListener("turbolinks:load", function(e) {
  siteReady();
}, false);